import React from 'react'
import Lottie from 'react-lottie'
import { Transition } from 'react-transition-group'

import ProxyLogo from 'assets/svg/proxy-logo-text.svg'
import ContentfulRichText from 'components/contentful-rich-text'

import animationData from './love.json'
import s from './Hero.scss'

const duration = 1000

const options = {
  loop: false,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid meet'
  }
}

const Hero = ({ pageDescription }) => {
  return (
    <div className={s.hero}>
      <div className={s.logoAndVideoWrapper} aria-labelledby="Proxy Love">
        <Transition appear in timeout={duration}>
          {state => <ProxyLogo className={`${s.logo} ${s[state]}`}/>}
        </Transition>
        <div className={s.video}>
          <Lottie width="100%" options={options} />
        </div>
      </div>
      <Transition appear in timeout={duration}>
        {state => (
          <div className={`${s.description} ${s[state]}`}>
            <ContentfulRichText content={pageDescription.json} />
          </div>
        )}
      </Transition>
    </div>
  )
}

export default Hero
