import React from 'react'
import PropTypes from 'prop-types'
import 'typeface-reenie-beanie'

import extractMeta from 'utils/extract-meta'
import SEO from 'components/seo'

import Grid from './components/grid'
import Highlight from './components/highlight'
import BackgroundLines from './components/background-lines'
import Hero from './components/hero'

import Modules from 'containers/modules'

import s from './Love.scss'

const TYPES = {
  grid: 'ContentfulProxyLoveTestimonialGrid',
  highlight: 'ContentfulProxyLoveTestimonialHighlight',
}

const SwitchComponents = ({ __typename: typeName, ...props }) => {
  if (typeName === TYPES.grid) return <Grid {...props} />
  if (typeName === TYPES.highlight) return <Highlight {...props} />
  return null
}

const ProxyLove = ({ data }) => (
  <div className={s.wrapper}>
    <SEO {...extractMeta(data.page)} />
    <BackgroundLines />
    <Hero {...data.page} />
    <div className={s.content}>
      {data.page.testimonials.map((testimonial, index) => (
        <SwitchComponents key={index} {...testimonial} />
      ))}
    </div>
    <Modules list={[data.contact.callToAction]} />
  </div>
)

export default ProxyLove
