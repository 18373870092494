import React, { useEffect, useState } from 'react'
import { INLINES, BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import getVideoId from 'get-video-id'

const richTextOptions = videoData => ({
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const { title, description, file } = node.data.target.fields
      const mimeType = file['en-US'].contentType
      const mimeGroup = mimeType.split('/')[0]
      const url = file?.['en-US']?.url

      if (mimeGroup === 'image')
        return (
          <img
            title={title?.['en-US']}
            alt={description?.['en-US']}
            src={url}
          />
        )

      if (mimeGroup === 'application')
        return (
          <a alt={description?.['en-US']} href={url}>
            {title?.['en-US'] || file?.['en-US']?.details?.fileName}
          </a>
        )

      return (
        <span style={{ backgroundColor: 'red', color: 'white' }}>
          {mimeType}
        </span>
      )
    },

    [INLINES.HYPERLINK]: node => {
      // vimeo video
      const video = videoData.find(video => video.link === node.data.uri)
      if (video) {
        return video.component
      }

      const data = getVideoId(node.data.uri)
      // youtube video
      if (data?.id && data.service === 'youtube') {
        return (
          <iframe
            width="660"
            height="371"
            src={`https://www.youtube.com/embed/${data.id}?rel=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ maxWidth: '100%' }}
          />
        )
      }
      // normal links
      return (
        <a href={node.data.uri}>
          {node.content.find(c => c.nodeType === 'text')?.value}
        </a>
      )
    },
  },
})

const findHyperlinks = content => {
  return content?.content
    ? content.content.reduce((allLinks, node) => {
        if (node.nodeType === INLINES.HYPERLINK) {
          return allLinks.concat(node.data.uri)
        }

        const links = findHyperlinks(node)
        return links ? allLinks.concat(links) : allLinks
      }, [])
    : null
}

const ContentfulRichText = ({ content }) => {
  const links = findHyperlinks(content)
  const [videoData, setVideoData] = useState([])

  useEffect(() => {
    // get vimeo data from oembed
    const getVideoData = async () => {
      const videos = await links.reduce(async (data, link) => {
        if (link.includes('vimeo.com')) {
          const response = await fetch(
            `https://vimeo.com/api/oembed.json?url=${link}&width=660`
          )
          const json = await response.json()

          return data.concat({
            link,
            component: (
              <iframe
                title={json?.title}
                src={`https://player.vimeo.com/video/${json.video_id}`}
                frameBorder="0"
                allowFullScreen
                width={json.width}
                height={json.height}
                style={{ maxWidth: '100%' }}
              />
            ),
          })
        }

        return data
      }, [])

      setVideoData(videos)
    }

    getVideoData()
  }, [])

  return documentToReactComponents(content, richTextOptions(videoData))
}

export default ContentfulRichText
