import React, { useRef, useEffect } from 'react'

import s from './BackgroundLines.scss'

const drawPath = (path, delay = 50) => {
  const length = path.getTotalLength()
  setTimeout(() => {
    path.style.strokeDasharray = length + ' ' + length
    path.style.strokeDashoffset = length
  }, delay)
}

const paths = [
  '.drawPath-01',
  '.drawPath-02',
  '.drawPath-03',
  '.drawPath-04',
]

const BackgroundLines = () => {
  const ref = useRef()
  useEffect(() => {
    paths.forEach((path, index) => (
      drawPath(ref.current.querySelector(path), (index + 1) * 2500)
    ))
  }, [])
  return (
    <svg className={s.wrapper} ref={ref} xmlns="http://www.w3.org/2000/svg" width="1860" height="11373" viewBox="0 0 1860 11373" fill="none" preserveAspectRatio='xMidYMid slice' vectorEffect="non-scaling-stroke">
      <g className={s.base}>
        <path d="M2059,3208.5 C1790.5,2993 1415.5,2331.5 1664,1685.5 C1912.5,1039.5 1724,682 1436.5,480 C1244.83333,345.333333 1180.66667,176.333333 1244,-27" />
        <path d="M365.5,5262 C-161.5,4333.5 -161,4076 243,3219 C512.333333,2647.66667 404.833333,2257.83333 -79.5,2049.5" />
        <path d="M2143.5,9510 C1592.83333,9080.33333 1423.83333,8571.83333 1636.5,7984.5 C1955.5,7103.5 1512.5,7206.5 1424.5,6691.5 C1365.83333,6348.16667 1511.33333,6120 1861,6007" />
        <path d="M2048,11373 C1269.33333,11240.6667 700.5,11046.8333 341.5,10791.5 C-197,10408.5 288,10087 458,9510 C628,8933 -31,8681 -31,8681" />
      </g>
      <g className={s.animate}>
        <path className="drawPath-01" d="M2059,3208.5 C1790.5,2993 1415.5,2331.5 1664,1685.5 C1912.5,1039.5 1724,682 1436.5,480 C1244.83333,345.333333 1180.66667,176.333333 1244,-27" />
        <path className="drawPath-02" d="M365.5,5262 C-161.5,4333.5 -161,4076 243,3219 C512.333333,2647.66667 404.833333,2257.83333 -79.5,2049.5" />
        <path className="drawPath-03" d="M2143.5,9510 C1592.83333,9080.33333 1423.83333,8571.83333 1636.5,7984.5 C1955.5,7103.5 1512.5,7206.5 1424.5,6691.5 C1365.83333,6348.16667 1511.33333,6120 1861,6007" />
        <path className="drawPath-04" d="M2048,11373 C1269.33333,11240.6667 700.5,11046.8333 341.5,10791.5 C-197,10408.5 288,10087 458,9510 C628,8933 -31,8681 -31,8681" />
      </g>
    </svg>
  )
}

export default BackgroundLines
