import React from 'react'

import { Container } from 'components/layout'
import ContentfulRichText from 'components/contentful-rich-text'
import AspectRatio from 'components/aspect-ratio'
import Image from 'components/image'

import s from './TwoImages.scss'

const TwoImages = ({ author, content, primaryImage, secondaryImage, ...props }) => (
  <div className={s.wrapper}>
    <Container>
      <div className={s.images}>
        {primaryImage && (
          <div className={s.image}>
            <AspectRatio ratio={10/8}>
              <Image alt={author} src={primaryImage.file.url} {...primaryImage.file.details.image} />
            </AspectRatio>
          </div>
        )}
        {secondaryImage && (
          <div className={s.image}>
            <AspectRatio ratio={10/8}>
              <Image alt={author} src={secondaryImage.file.url} {...secondaryImage.file.details.image} />
            </AspectRatio>
          </div>
        )}
      </div>
      <div className={s.text}>
        {content?.json && (
          <blockquote className={s.content}>
            <ContentfulRichText content={content.json} />
          </blockquote>
        )}
        {author && <div className={s.author}>{author}</div>}
      </div>
    </Container>
  </div>
)

export default TwoImages
