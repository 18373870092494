import React from 'react'
import ContentfulRichText from 'components/contentful-rich-text'
import AspectRatio from 'components/aspect-ratio'
import Image from 'components/image'

import { Container } from 'components/layout'

import s from './Left.scss'

const Left = ({ author, content, primaryImage, secondaryImage, ...props }) => (
  <Container>
    <div className={s.wrapper}>
      {primaryImage && (
        <div className={s.primaryImage}>
          <Image
            transition="wipe"
            alt={author}
            src={primaryImage.file.url}
            {...primaryImage.file.details.image}
          />
        </div>
      )}
      <div className={s.right}>
        <div className={s.content}>
          {content?.json && (
            <blockquote className={s.quote}>
              <ContentfulRichText content={content.json} />
            </blockquote>
          )}
          {author && <div className={s.author}>{author}</div>}
        </div>
        {secondaryImage && (
          <div className={s.secondaryImage}>
            <Image
              transition="wipe"
              alt={author}
              src={secondaryImage.file.url}
              {...secondaryImage.file.details.image}
            />
          </div>
        )}
      </div>
    </div>
  </Container>
)

export default Left
