import { graphql } from 'gatsby'
import ProxyLove from 'routes/love/Love'

export default ProxyLove

export const query = graphql`
  query ProxyLove {
    page: contentfulPageProxyLove {
      metaTitle
      metaDescription
      pageTitle
      pageDescription {
        json
      }
      testimonials {
        ... on ContentfulProxyLoveTestimonialHighlight {
          layoutType
          author
          content {
            json
          }
          primaryImage {
            ...image
          }
          secondaryImage {
            ...image
          }
        }
        ... on ContentfulProxyLoveTestimonialGrid {
          testimonial1Author
          testimonial1Content {
            json
          }
          testimonial1Image {
            ...image
          }

          testimonial2Author
          testimonial2Content {
            json
          }
          testimonial2Image {
            ...image
          }

          testimonial3Author
          testimonial3Content {
            json
          }
          testimonial3Image {
            ...image
          }

          testimonial4Author
          testimonial4Content {
            json
          }
          testimonial4Image {
            ...image
          }

          testimonial5Author
          testimonial5Content {
            json
          }
          testimonial5Image {
            ...image
          }

          testimonial6Author
          testimonial6Content {
            json
          }
          testimonial6Image {
            ...image
          }
        }
      }
    }
    contact: contentfulPagePricing {
      callToAction {
        ...moduleList
      }
    }
  }
`
