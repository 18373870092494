import React from 'react'

import { Container } from 'components/layout'
import ContentfulRichText from 'components/contentful-rich-text'
import AspectRatio from 'components/aspect-ratio'
import Image from 'components/image'

import s from './Grid.scss'

const getArray = props => Object.entries(props).reduce((acc, [_key, value]) => {
  const index = parseInt(_key.replace('testimonial', '')) - 1
  if(!isFinite(index)) return acc
  const key = _key.replace(/testimonial*./g, '').toLowerCase()
  return [
    ...acc.slice(0, index),
    { ...acc?.[index], [key]: value },
    ...acc.slice(index + 1),
  ]
}, []).filter(({ author }) => !!author)

const getLayoutClass = length => length === 4 ? s.fourItems : s.sixItems

const getDesktopRatio = length => length === 4 ? 10/8 : 10/6

const Grid = props => (
  <Container>
    <ul className={s.quoteList}>
      {getArray(props)?.map(({ image, content, author }, i, { length }) => (
        <li key={author} className={getLayoutClass(length)}>
          {image && (
            <AspectRatio ratio={10/8} desktopRatio={getDesktopRatio(length)}>
              <div className={s.quoteImage}>
                <Image alt={author} src={image.file.url} {...image.file.details.image} />
              </div>
            </AspectRatio>
          )}
          {content?.json && (
            <blockquote className={s.quoteContent}>
              <ContentfulRichText content={content.json} />
            </blockquote>
          )}
          {author && (
            <div className={s.quoteAuthor}>{author}</div>
          )}
        </li>
      ))}
    </ul>
  </Container>
)

export default Grid
