import React from 'react'

import Left from './Left'
import Full from './Full'
import Right from './Right'
import TwoImages from './TwoImages'

const TYPES = {
  left: 'image on left',
  right: 'image on right',
  full: 'full bleed',
  two: 'two images',
}

const findType = type =>
  Object.entries(TYPES).find(([key, value]) => value === type)[0]

const Highlight = ({ layoutType, ...props }) => {
  const type = findType(layoutType)
  if(type === 'left') return <Left {...props} />
  if(type === 'full') return <Full {...props} />
  if(type === 'right') return <Right {...props} />
  if(type === 'two') return <TwoImages {...props} />
  return null
}

export default Highlight
