import React from 'react'
import ContentfulRichText from 'components/contentful-rich-text'
import AspectRatio from 'components/aspect-ratio'
import Image from 'components/image'

import { Container } from 'components/layout'

import s from './Full.scss'

const Full = ({
  author,
  content,
  primaryImage: mobileImage,
  secondaryImage: desktopImage,
  ...props
}) => (
  <div className={s.wrapper}>
    <div className={s.mobile}>
      {mobileImage && (
        <div className={s.mobileImage}>
          <Image
            transition="wipe"
            alt={author}
            src={mobileImage.file.url}
            {...mobileImage.file.details.image}
          />
        </div>
      )}
      <div className={s.content}>
        {content?.json && (
          <blockquote className={s.quote}>
            <ContentfulRichText content={content.json} />
          </blockquote>
        )}
        {author && <div className={s.author}>{author}</div>}
      </div>
    </div>
    {desktopImage && (
      <div className={s.desktop}>
        <div className={s.desktopImage}>
          <Image
            transition="wipe"
            alt={author}
            src={desktopImage.file.url}
            {...desktopImage.file.details.image}
          />
        </div>
      </div>
    )}
  </div>
)

export default Full
